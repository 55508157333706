<template>
  <div>
    <CarouselComponent />
    <section>
      <article>
        <div
          class="container"
          style="margin: 50px auto; text-align: left"
        >
          <div class="dining_introduction">
            <h3>Trekker's Tavern Cottages</h3>
            <p style="font-weight: 600; font-size: 1.2em">
              Fine Dining Restaurant in Bwindi
            </p>
            <p style="font-weight: 300">
              Welcome to Cassowary Restaurant, “a foodie’s heaven” where you
              have access to a varied menu covering a selection of carefully
              chosen cuisines ranging from continental, Ugandan, Asian et all
              and fit for all ages. Special thought has been given to specific
              dietary conditions and healthy living.
            </p>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article style="border: 1px solid transparent; 
  background-color: #fef9f6;">
        <div
          class="container"
          style="margin: 0px auto 50px auto; text-align: left"
        >
          <div class="dining_grid">
            <div
              class="dining_flex"
              v-for="dining in dining_features"
              :key="dining.id"
            >
              <div>
                <div class="dining_grid_image">
                  <img :src=" dining.image " />
                </div>
                <div class="dining_features">
                  <div class="mt-3">
                    <p style="font-weight: 600; font-size: 1.2em">
                      {{ dining.title }}
                    </p>
                    <p style="font-weight: 300">
                      {{ dining.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import CarouselComponent from "../components/dining/carousel-component.vue";

export default {
  components: { CarouselComponent },

  data() {
    return {
      table_drawer: false,
      direction: "rtl",

      dining_features: [
        {
          id: "1",
          title: "Restaurant & Bar",
          description:
            "Cassowary Restaurant, “a foodie’s heaven” where you have access to a varied menu covering a selection of carefully chosen cuisines",
          image: require("../assets/images/trekkers-tavern-cottages.jpg"),
        },
        {
          id: "2",
          title: "Terrace",
          description:
            "Relax and sip an exotic drink or do business over a cup of coffee at the Penguin Bar. With a view overlooking the hotels swimming pool",
          image: require("../assets/images/home_image2.jpg"),
        },
        {
          id: "6",
          title: "Meet The Chef.",
          description:
            "The man with gifted hands!” Chef Patrick started his culinary journey 15 years ago when he realized his passion for cookery.  He trained under the best Chefs in Leading Hotels and restaurants which include Serena, Le Chateau, Marasa Africa group where he led the Kitchen brigade in several properties before coming in to be the pioneer Chef at  Hotel.",
          image: require("../assets/images/home_image4.jpg"),
        },
      ],
    };
  },

  methods: {
    handleClose(done) {
      done();
    },

    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dining_introduction {
  width: 60%;
}

.dining_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.dining_flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 5px;
}

.dining_flex > div:nth-child(3) {
  border-radius: 0px 0px 5px 5px;
}

.dining_grid > div .dining_grid_image img {
  height: 300px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.goBtn {
  color: white;
  font-weight: 600;
  transition: 0.5s;
}

.dining_grid > div:hover .goBtn {
  margin-left: 20px;
  transition: 0.5s;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }
}
</style>
