<template>
  <div>
    <article class="theCarouselArticle">
      <el-carousel
        height="80vh"
        :interval="5000"
        arrow="always"
      >
        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/home_image1.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <p class="trekkers_word_style">
                  <small>TREKKER'S TAVERN COTTAGES</small>
                </p>
                <p style="font-size: 1.5em">FINE DINING AT TREKKER'S TAVERN COTTAGES</p>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/home_image2.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <p class="trekkers_word_style">
                  <small>TREKKER'S TAVERN COTTAGES</small>
                </p>
                <p style="font-size: 1.5em">FINE DINING AT TREKKER'S TAVERN COTTAGES</p>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/home_image3.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <p class="trekkers_word_style">
                  <small>TREKKER'S TAVERN COTTAGES</small>
                </p>
                <p style="font-size: 1.5em">FINE DINING AT TREKKER'S TAVERN COTTAGES</p>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/home_image4.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <p class="trekkers_word_style">
                  <small>TREKKER'S TAVERN COTTAGES</small>
                </p>
                <p style="font-size: 1.5em">FINE DINING AT TREKKER'S TAVERN COTTAGES</p>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/home_image2.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <p class="trekkers_word_style">
                  <small>TREKKER'S TAVERN COTTAGES</small>
                </p>
                <p style="font-size: 1.5em">FINE DINING AT TREKKER'S TAVERN COTTAGES</p>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </article>

    <article>
      <div class="menu_list">
        <el-drawer
          title="Explore Our Menu"
          :visible.sync="drawer"
          :direction="direction"
          :before-close="handleClose"
        >
          <div class="row">
            <div class="col-md-6 mt-5">
              <h2>Coffee Corner</h2>
              <hr />
              <el-table
                :data="coffeeCornertableData"
                style="width: 100%"
              >
                <el-table-column
                  prop="item"
                  label="Item"
                >
                </el-table-column>
                <el-table-column
                  prop="price"
                  label="Sales Price"
                >
                </el-table-column>
              </el-table>
            </div>

            <div class="col-md-6 mt-5">
              <h2>Teas</h2>
              <hr />
              <el-table
                :data="teastableData"
                style="width: 100%"
              >
                <el-table-column
                  prop="item"
                  label="Item"
                >
                </el-table-column>
                <el-table-column
                  prop="price"
                  label="Sales Price"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mt-5">
              <h2>Iced Drinks</h2>
              <hr />
              <el-table
                :data="icedDrinkstableData"
                style="width: 100%"
              >
                <el-table-column
                  prop="item"
                  label="Item"
                >
                </el-table-column>
                <el-table-column
                  prop="price"
                  label="Sales Price"
                >
                </el-table-column>
              </el-table>
            </div>

            <div class="col-md-6 mt-5">
              <h2>Accompanying Syrups</h2>
              <hr />
              <el-table
                :data="syrupstableData"
                style="width: 100%"
              >
                <el-table-column
                  prop="item"
                  label="Item"
                >
                </el-table-column>
                <el-table-column
                  prop="price"
                  label="Sales Price"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>

        </el-drawer>
      </div>
    </article>
  </div>
</template>

<script>
// import MenuComponent from '../dining/menu-component.vue'
export default {
  // components: {MenuComponent},
  data() {
    return {
      drawer: false,
      direction: "rtl",
      num: 1,
      value1: "",

      coffeeCornertableData: [
        {
          item: "Esspresso",
          price: "UGX 8000",
        },
        {
          item: "Capuccino",
          price: "UGX 15000",
        },
        {
          item: "Chococcino",
          price: "UGX 15000",
        },
        {
          item: "Mocha ",
          price: "UGX 15000",
        },
        {
          item: "Machiato",
          price: "UGX 15000",
        },
        {
          item: "Latte Machiato",
          price: "UGX 15000",
        },
        {
          item: "Café Latte",
          price: "UGX 15000",
        },
        {
          item: "African Coffee",
          price: "UGX 15000",
        },
        {
          item: "Black Coffee",
          price: "UGX 10000",
        },
        {
          item: "Hot Chocolate",
          price: "UGX 15000",
        },
        {
          item: "Glass of Milk",
          price: "UGX 15000",
        },
      ],

      teastableData: [
        {
          item: "African Tea",
          price: "UGX 15000",
        },
        {
          item: "Chai Latte",
          price: "UGX 15000",
        },
        {
          item: "Black Tea",
          price: "UGX 15000",
        },
        {
          item: "Dawa Tea",
          price: "UGX 12000",
        },
        {
          item: "Lemon Tea",
          price: "UGX 15000",
        },
        {
          item: "Mint Tea",
          price: "UGX 15000",
        },
        {
          item: "Lemongrass Tea",
          price: "UGX 15000",
        },
        {
          item: "Green Tea",
          price: "UGX 15000",
        },
        {
          item: "Café Latte",
          price: "UGX 15000",
        },
        {
          item: "Herbal Teas (Peppermint, Chamomile, Raspberry, Blueberry, Earl Grey)",
          price: "UGX 15000",
        },
        {
          item: "Darjeeling Tea",
          price: "UGX 15000",
        },
      ],

      icedDrinkstableData: [
        {
          item: "Iced Tea",
          price: "UGX 15000",
        },
        {
          item: "Iced Coffee",
          price: "UGX 15000",
        },
        {
          item: "Frappe",
          price: "UGX 18000",
        },
      ],

      syrupstableData: [
        {
          item: "Caramel",
          price: "UGX 5000",
        },
        {
          item: "Vannilla",
          price: "UGX 5000",
        },
        {
          item: "Cinamon",
          price: "UGX 5000",
        },
        {
          item: "Hazzelnut",
          price: "UGX 5000",
        },
      ],
    };
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    handleClose(done) {
      done();
    },
  },
};
</script>

<style scoped>
.menu_list h2 {
  font-size: 1.2em;
  font-weight: 600;
}
.trekkers_word_style {
  font-size: 0.5em;
  font-weight: 300;
  word-spacing: 8px;
  letter-spacing: 5px;
}

.goBtn {
  color: #fefbf7;
  font-weight: 600;
  transition: 0.5s;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.carousel_description {
  position: relative;
  height: 80vh;
}
.carousel_description img {
  height: 80vh;
  object-fit: cover;
}

.carousel_description .image-description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  padding-bottom: 10%;
  /* background-color: #fef9f6; */
  display: flex;
  align-items: flex-end;
}

.carousel_description .image-description > div {
  /* background-color: green; */
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 600;
  color: white;
  text-align: left;
}

.moreBtn {
  background-color: transparent;
  color: #fbf2e1;
  border: 1px solid #fbf2e1;
  border-radius: 0px;
}

.moreBtn:hover {
  background-color: rgba(61, 41, 10, 0.5);
  color: #fbf2e1;
  border: 1px solid #fbf2e1;
  border-radius: 0px;
}

.theCarouselArticle {
  height: 80vh;
  background-color: #f4f2e4;
  position: relative;
}
.availabilityForm {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.availabilityForm > div {
  font-size: 0.8em;
}

.checkBtn {
  background-color: #0e763c;
  border: 2px solid #0e763c;
  color: #fbf2e1;
  border-radius: 0px;
  text-transform: uppercase;
}

.menu_list ul {
  list-style: none;
  padding: 0px;
  margin: 20px 0px;
}

.menu_list ul li {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .availabilityForm {
    display: block;
  }

  .checkBtn {
    width: 100%;
  }

  .carousel_description .image-description {
    padding-bottom: 55%;
  }
}
</style>

<style>
.el-carousel__button {
  display: block;
  opacity: 0.48;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
}
.menu_list {
  text-align: left;
}
.menu_list .el-drawer_body {
  /* padding: 20px; */
}

.menu_list .el-table th.el-table__cell {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f6e0bf;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  transition: background-color 0.25s ease;
  background-color: #fcf4e9;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell:first-child {
  transition: background-color 0.25s ease;
  background-color: #fcf4e9;
  font-size: 1em;
  font-weight: 600;
  color: #3d290a;
}

.input-class .el-input__inner {
  width: 50px !important;
}

.el-date-editor .el-range-input {
  /* width: 20% !important; */
}

.el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 100% !important;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner {
    margin-bottom: 20px;
  }

  .el-input-number.is-controls-right {
    width: 100%;
    margin-bottom: 20px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .el-date-editor--datetimerange.el-input,
  .el-date-editor--datetimerange.el-input__inner {
    margin-bottom: 20px;
  }

  .el-input-number.is-controls-right {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
